import {useQuery} from "react-query";
import Constants from "../../Utils/Constants";
import {DocumentServices} from "../../Services/documents";
import {getDMSFeatureFlag} from "../../Utils/DMSConstants";

export const useGetDocuments = (applicationId) =>{
        return useQuery([Constants.REACT_QUERY.QUERY_KEY.GET_DOCUMENTS,applicationId],()=>{

                    let body = {
                        candidateIds: [applicationId],
                        userType: "CANDIDATE",
                    }
                    return DocumentServices.getDocuments(body)
        }
            ,{
                enabled: getDMSFeatureFlag(),
                refetchOnMount: true
                // refetchInterval: refetchInterval
            })
}