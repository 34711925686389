import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      color: '#ffffff',
    },
    small: {
        width: theme.spacing(6),
        height: theme.spacing(6),
      },
  }));

const Footer = (props) => {
    const classes = useStyles();

    return (
        <div>
            <AppBar position="static" color="secondary" className={classes.root}>
                <Toolbar>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default withAlertSnackBar(Footer)
