import {candidateAxios} from "../api/axios";

export const UserServices = {
    signIn,
    verifySession,
    resetTempPassword,
    sendOtp,
    forgotPassword,
    resetPassword,
    getDMSFlag,
    getAppConfig
}

async function signIn(body) {
    return await candidateAxios.post(`/candidateAuthentication/signIn`,body)
}

async function resetTempPassword(body) {
    return await candidateAxios.post(`/candidateAuthentication/resetTempPassword`, body)
}

async function verifySession() {
    const body = {
        idToken : localStorage.getItem('idtoken'),
        accessToken : localStorage.getItem('accesstoken')
        } 
    return await candidateAxios.post(`/candidateAuthentication/verifySession`,body)
}

async function sendOtp(body) {
    return await candidateAxios.post(`/candidateAuthentication/sendOtp`, body)
}

async function forgotPassword(body) {
    return await candidateAxios.post(`/candidateAuthentication/forgotPassword`, body)
}

async function resetPassword(body) {
    return await candidateAxios.post(`/candidateAuthentication/resetPassword`, body)
}

async function getDMSFlag(body) {
    return await candidateAxios.post(`/candidateAuthentication/getDMSFlag`, body)
}

// Browser is caching response, which caches response header 'accesstoken'
// Problematic after logout since server still caching 'accesstoken'
// Pass current time to avoid browser caching, permanent fix would be to use cache-control (Proxy change)
async function getAppConfig() {
    return await candidateAxios.get(`/candidateAuthentication/getAppConfig?_=${new Date().getTime()}`)
}