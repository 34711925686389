import React from 'react'

export function Formvalidationerror(props) {   
    return (
        <p className='amz_field_error'><small>{props.fieldName} is missing</small></p>
    )
}

export function FormNonZerovalidationerror(props) {
    return (
        <p className='amz_field_error'><small>{props.fieldName} should be greater than zero</small></p>
    )
}

export function FormComparevalidationerror(props) {
    return (
        <p className='amz_field_error'><small>{props.fieldName} should be greater than head count</small></p>
    )
}

export function FormPasswordMismatchError() {
    return (
        <p className='amz_field_error'><small>Passwords do not match</small></p>
    )
}

export function FormError(props) {
    return (
        <p className='amz_field_error'><small>{props.error}</small></p>
    )
}

export function FormPastDatevalidationerror(props){
    return (
        <p className='amz_field_error'><small>{props.fieldName} cannot be past date</small></p>
    )
}