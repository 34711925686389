import {candidateAxios} from "../api/axios";

export const DocumentServices = {
    getDocuments,
    signDocument
}

async function getDocuments(body){
    const response = await candidateAxios.post('/document/list',body);
    return response;
}

async function signDocument(body){
    const response = await candidateAxios.post('/document/sign',body);
    return response;
}
