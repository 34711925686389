import constants from "./Constants";

export function getCandidateHiringStage(hiringStage){
    let result = constants.HIRING_STAGES.DB_UI_MAP[hiringStage]
    if(result!== undefined){
        return result
    }
    else {
        return hiringStage
    }
}