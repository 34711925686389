import {candidateAxios } from '../api/axios'

export const UploadDownloadS3File = {
    downloadConstruct,
    downloadFiles
}

async function downloadConstruct(fileName) {
    const body = {
        fileName : fileName,
    };
    const response = await candidateAxios.post(`/S3/downloadConstruct`,body);
    if(response["data"]["sessionExpired"]){
        window.alert("Invalid Session");
        localStorage.clear();
        window.location.href='/';
    }
    return response;
}

async function downloadFiles(fileNames ,s3BucketName) {
    const body = {
        fileNames : fileNames,
        bucketName: s3BucketName
    };
    const response = await candidateAxios.post(`/S3/downloadFiles`,body);
    if(response["data"]["sessionExpired"]){
        window.alert("Invalid Session");
        localStorage.clear();
        window.location.href='/';
    }
    return response;
}
