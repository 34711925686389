import React from 'react';
import { Chip as MUIChip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Copying Stencil's Chip component color design:
// See https://stencil.a2z.com/components/chip
const useStyles = makeStyles((theme) => ({
  root: ({ backgroundColor }) => ({
    height: '32px',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.neutral['90'],

    '&>svg': {
      width: '16px',
      height: '16px',
    },

    ...(backgroundColor === 'default' && {
      backgroundColor: '#e8eaee',
    }),

    ...(backgroundColor === 'yellow' && {
      backgroundColor: '#fff3cc',
    }),

    ...(backgroundColor === 'blue' && {
      backgroundColor: '#d7ebfb',
    }),

    ...(backgroundColor === 'green' && {
      backgroundColor: '#d7f3ed',
    }),
  }),
}));

function Chip({ backgroundColor, ...props }) {
  const classes = useStyles({ backgroundColor });
  return <MUIChip className={classes.root} {...props} />;
}

export default Chip;
