import { createTheme } from '@material-ui/core/styles';

const primaryTheme = createTheme({
  breakpoints: {
    values: {
        xs: 0,
        sm: 768,
        md: 960,
        lg: 1280,
        xl: 1440,
    }
  },
palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#1768C9',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#232f3e',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    error: {
      main: '#BB2929',
    },
    neutral: {
        '0': '#ffffff',
        '05': '#f1f4f7',
        10: '#e8eaee',
        20: '#D1D5DA',
        30: '#b9c0c8',
        50: '#8b96a3',
        70: '#56616c',
        90: '#232f3e',
        100: '#161e2d',
      },
    yellow: {
      '05': '#fff9e6',
      60: '#ca8700',
      70: '#b27400',
      80: '#8E5D00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  spacing: (factor) => `${0.25 * factor}rem`,
  typography: {
    h1: {
      fontSize: '2.5rem',
      fontWeight: '700',
      lineHeight: 1.5,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: '700',
      lineHeight: 1.5,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '700',
      lineHeight: 1.5,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: '700',
      lineHeight: 1.5,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: '700',
      lineHeight: 1.5,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: '700',
      lineHeight: 1.5,
    },
    fontFamily: ['Amazon Ember'].join(','),
  },
});

export default primaryTheme;
