import React, { useState, useEffect } from 'react';
import { Grid, Menu, MenuItem, Button, Typography, Toolbar, AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../Title/Title';
import UserAvatar from '../UserAvatar/UserAvatar';
import ResetPasswordDialog from '../../pages/Auth/ResetPasswordDialog';
import { withAlertSnackBar } from '../../HOComponents/AlertSnackBarHOC';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: '#ffffff',
  },
  title: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',

    [theme.breakpoints.down('xs')]: {
      left: '1rem',
      transform: 'translate(0%,-50%)',
      fontSize: '1.25rem',
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sessionUser, setSessionUser] = useState(null);
  const [changePassword, setChangePassword] = React.useState(false);

  useEffect(() => {
    const localSessionUser = localStorage.getItem('session_user');

    if (localSessionUser) {
      setSessionUser(localSessionUser);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" color="secondary" className={classes.root}>
      <Toolbar>
        <Typography variant="h3" className={classes.title}>
          <Title />
        </Typography>
        {sessionUser && (
          <Grid container alignItems="center" justifyContent="flex-end">
            <UserAvatar />

            <Typography variant="h6">
              <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={{ color: 'WHITE' }}
              >
                {sessionUser ? sessionUser : ''}
              </Button>
            </Typography>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  setChangePassword(true);
                }}
              >
                Change Password
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            {changePassword && (
              <ResetPasswordDialog
                snackbarShowMessage={props.snackbarShowMessage}
                changePassword={changePassword}
                setChangePassword={setChangePassword}
              />
            )}
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default withAlertSnackBar(Header);
