import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {Card, Grid, Step, StepConnector, StepLabel, Stepper, Typography} from "@mui/material";
import constants from "../../Utils/Constants";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

function DocumentCard(props){
    const [width, setWidth] = useState(window.innerWidth);
    const history = useHistory()

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const documentActivity = () =>{

        history.push({pathname:constants.DOCUMENTS.VIEW, state:{
                documentType: props.document.type,
                application: props.application,
                documentData: props.docData
            }})
    }
    return (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                <Card sx={{ minWidth: 180, borderRadius: 1, borderColor:"#000000", marginBottom: "15px",padding:"20px" ,paddingLeft:"3rem", paddingRight:"3rem"}} variant="outlined" >
                    <Grid container justifyContent={isMobile?"center":"space-between"}>

                        <Typography gutterBottom sx={{fontSize:isMobile?"0.875rem":"1.1rem",  fontFamily:"Amazon Ember"}}>
                            {` ${props.document.type}`}
                        </Typography>

                        <Stepper
                                 sx={{
                                     "& .MuiStepConnector-line": {
                                         borderTopWidth: "3px",
                                     },marginTop:"10px"
                                 }}
                                 className="scroller"
                                 activeStep={constants.DOCUMENTS.DOCUMENT_PROGRESS_MAP[props.document.type][props.application.hiringStage] }
                                 alternativeLabel
                                 connector={<StepConnector/>}>
                            {constants.DOCUMENTS.PROGRESS_STEPS.map((label) => (
                                <Step sx={{minWidth: isMobile?"6rem":"20rem"}} className=" documentStep" key={label}>
                                    <StepLabel className="stepLabel">{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        <Grid>
                            <ButtonPrimary id="btnDocumentView" onClick={() =>documentActivity()} size="medium" sx={{margin: '0px'}} Title="Review"/>
                        </Grid>

                    </Grid>

                </Card>
            </Grid>
    );
}

export default withAlertSnackBar(DocumentCard);