import React, { memo } from 'react'
import { Route, Redirect } from 'react-router'
import Header from "../components/Header/Header";

const Public = ({ component: Component, restricted, auth,header, ...rest }) => {
  
  return (
    <>
      {header && <Header/>}
      <Route {...rest} render={props => (
      auth ? <Redirect to={'/candidate-dashboard'} /> : <Component {...props} />
      )} />
    </>
    
  )
}

export default memo(Public)
