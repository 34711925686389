export const PHO_PORTAL_BASE_URL = {
    alpha: {
        AE : "https://alpha.learnings.warehousejobsuae.com",
        SA : "https://alpha.learnings.warehousejobsksa.com",
        EG : "https://alpha.learnings.warehousejobseg.com",
        IN : "https://learnings.alpha.hvhindia.in/"
    },
    beta: {
        SA : "https://beta.learnings.warehousejobsksa.com",
        EG : "https://beta.learnings.warehousejobseg.com",
        AE : "https://beta.learnings.warehousejobsuae.com",
        IN : "https://learnings.beta.hvhindia.in/"
    },
    gamma: {
        SA : "https://gamma.learnings.warehousejobsksa.com",
        AE : "https://gamma.learnings.warehousejobsuae.com",
        EG : "https://gamma.learnings.warehousejobseg.com",
        IN : "https://learnings.gamma.hvhindia.in/"
    },
    prod: {
        SA : "https://learnings.warehousejobsksa.com",
        AE : "https://learnings.warehousejobsuae.com",
        EG : "https://learnings.warehousejobseg.com",
        IN : "https://learnings.hvhindia.in/"
    }
}

export const EDM_PORTAL_BASE_URL = {
    alpha: {
        SA : "https://ksa_candidate.beta.docs.amazon.com",
        AE : "https://uae_candidate.beta.docs.amazon.com",
        EG : "https://egy_candidate.beta.docs.amazon.com",
    },
    beta: {
        SA : "https://ksa_candidate.beta.docs.amazon.com",
        AE : "https://uae_candidate.beta.docs.amazon.com",
        EG : "https://egy_candidate.beta.docs.amazon.com",
    },
    gamma: {
        SA : "https://ksa_candidate.gamma.docs.amazon.com",
        AE : "https://uae_candidate.gamma.docs.amazon.com",
        EG : "https://egy_candidate.gamma.docs.amazon.com",
    },
    prod: {
        SA : "https://ksa_candidate.docs.amazon.com",
        AE : "https://uae_candidate.docs.amazon.com",
        EG : "https://egy_candidate.docs.amazon.com",
    }
};

export const getEDMUrl = (applicationDCRId) => {
    const baseUrl = EDM_PORTAL_BASE_URL[sessionStorage.getItem('stage')][sessionStorage.getItem('countryCode')];

    if(!applicationDCRId) {
        return baseUrl;
    }
    return `${baseUrl}/documents/view/${applicationDCRId}`
}

export let isMENACountry = () =>{
    const country = sessionStorage.getItem('countryCode');
    return country === "AE" || country === "EG" || country === "SA";
}

export const getPhoBaseUrl = () =>{
    return PHO_PORTAL_BASE_URL[sessionStorage.getItem('stage')][sessionStorage.getItem('countryCode')]
}

// SR Grievance Ingress Point SOP: https://quip-amazon.com/OKwIAuzab2cy/SR-Grievance-Ingress-Point-SOP
export const GRIEVANCE_CHATBOT_BASE_URL = {
    alpha: {
        SA : "https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=Saudi%20Arabia&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=CandidateDashboard",
        AE : "https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=United%20Arab%20Emirates&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=CandidateDashboard",
        EG : "https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=Egypt&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=CandidateDashboard",
    },
    beta: {
        SA : "https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=Saudi%20Arabia&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=CandidateDashboard",
        AE : "https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=United%20Arab%20Emirates&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=CandidateDashboard",
        EG : "https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=Egypt&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=CandidateDashboard",
   },
    gamma: {
        SA : "https://preprod-mena.fra.chat.hvh.a2z.com/?language=English&country=Saudi%20Arabia&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=CandidateDashboard",
        AE : "https://preprod-mena.fra.chat.hvh.a2z.com/?language=English&country=United%20Arab%20Emirates&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=CandidateDashboard",
        EG : "https://preprod-mena.fra.chat.hvh.a2z.com/?language=English&country=Egypt&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=CandidateDashboard"
    },
    prod: {
        SA : "https://mena.fra.chat.hvh.a2z.com/?language=English&country=Saudi%20Arabia&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=CandidateDashboard",
        AE : "https://mena.fra.chat.hvh.a2z.com/?language=English&country=United%20Arab%20Emirates&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=CandidateDashboard",
        EG : "https://mena.fra.chat.hvh.a2z.com/?language=English&country=Egypt&type=Associate%20/%20Driver%20fra/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=CandidateDashboard",
    }
}

export function getGrievanceChatbotUrl(){
    const stage = sessionStorage.getItem('stage');
    const countryCode = sessionStorage.getItem('countryCode');
    return GRIEVANCE_CHATBOT_BASE_URL?.[stage]?.[countryCode];
}