import React from 'react';
import ReactDOM from 'react-dom';
import './variables.scss';
import './components/TabBar/TabMain.scss'
import './global.scss';
import { ThemeProvider } from "@material-ui/core/styles";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {QueryClientProvider, QueryClient} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools"
import primaryTheme from './theme';


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            cacheTime: 1000*60*30,
            retry:false
        },
    },
})

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={primaryTheme}>
            <QueryClientProvider client={queryClient}>
               <App />
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </ThemeProvider>
    </React.StrictMode>,

    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
