import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BellIcon from "react-bell-icon";
import constants from "../../Utils/Constants";

function AlertDialog(props){

    return(
        <Grid sx={{padding:"0" ,margin:"10px 0px", width:"100%"}}  maxWidth={"100%"}>
            <Accordion id="alertAccordion" sx={{border:"1px solid #ff0000", padding:"0"}} className="form_group_item">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="alert-header">
                    <Grid wrap={"nowrap"} container direction={"row"} style={{marginLeft: "20px"}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <BellIcon width='30' active={true} color={"#ff0000"} animationSpeed={"0.5"} animate={true} />
                        <Grid sx={{marginTop:props.isMobile?"0.2rem":"0.5rem", marginLeft:"10px"}}>
                            <Typography id="alertTitle" style={{fontWeight:550, fontSize:props.isMobile?15:18}} >{constants.DOCUMENTS.ALERT_TEXT}</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails id="alertDetails" container item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid id="alertText" style={{marginLeft: "2.5rem", marginTop:"0px"}}>
                        {props.alert}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}

export default withAlertSnackBar(AlertDialog)