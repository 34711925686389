import axios from 'axios'
import constants from "../Utils/Constants";
const cathodeVersions = require('@amzn/cathode-versions-javascript');

let counter = 0;


let candidatePortalURL
let phoPortalURL
let candidateAxios
let phoAxios
let careerSiteURL

let stage, countryCode

const init = async () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if(sessionStorage.getItem('assignedPhoJobId')===null) {
    sessionStorage.setItem('assignedPhoJobId', urlParams.get('assignedPhoJobId'));
  }
  candidatePortalURL = sessionStorage.getItem('candidatePortalURL');
  phoPortalURL = sessionStorage.getItem('phoPortalURL');
  careerSiteURL = sessionStorage.getItem('careerSiteURL')
  countryCode = sessionStorage.getItem('countryCode')
  if(candidatePortalURL=== null || phoPortalURL=== null){
    await axios.get('/settings.json')
        .then((res)=>{
          console.log("fetching environment specific URLs")
          candidatePortalURL = res.data.candidatePortalProxy
          phoPortalURL = res.data.phoPortalProxy
          careerSiteURL = res.data.careerSiteHost
          stage = res.data.stage
          countryCode = res.data.country
        }).catch((err)=>{
          console.log("configuration file not found")
          candidatePortalURL = process.env.REACT_APP_CANDIDATE_PORTAL_BASE_URL
          phoPortalURL = process.env.REACT_APP_PHO_PROXY_URL
          careerSiteURL = process.env.REACT_APP_CAREER_SITE_URL
          stage = process.env.REACT_APP_STAGE
          countryCode = process.env.REACT_APP_COUNTRY
        })
    sessionStorage.setItem('candidatePortalURL',candidatePortalURL);
    sessionStorage.setItem('phoPortalURL',phoPortalURL);
    sessionStorage.setItem(constants.SPECTROMETER.STAGE,stage);
    sessionStorage.setItem('careerSiteURL', careerSiteURL);
    sessionStorage.setItem('countryCode',countryCode);
  }
  setAxios();
}

const setAxios = () => {
  counter = 0;
  candidateAxios = axios.create({
    withCredentials: true,
    baseURL: candidatePortalURL,
    timeout: 180000
  })
  candidateAxios.interceptors.request.use(handleSuccessRequest, handleErrorRequest)
  candidateAxios.interceptors.response.use(handleSuccess, handleError)

  phoAxios = axios.create({
    withCredentials: true,
    baseURL: phoPortalURL,
    timeout: 180000
  })
  phoAxios.interceptors.request.use(handleSuccessRequestWithoutTokenRefresh, handleErrorRequest)
  phoAxios.interceptors.response.use(handleSuccessWithoutTokenRefresh, handleError)
}

const handleSuccessRequestWithoutTokenRefresh = (request) => {
  document.body.classList.add('loading_indicator')
  counter = counter + 1;
  return request
}

const handleSuccessRequest = (request) => {
  if(!constants.URL_WITHOUT_LOADING_ICON.includes(request.url))
    document.body.classList.add('loading_indicator')
  counter = counter + 1;
  request.headers['refreshtoken'] = localStorage.getItem('refreshtoken');
  request.headers['idtoken'] = localStorage.getItem('idtoken');
  request.headers['accesstoken'] = localStorage.getItem('accesstoken');
  request.headers['user'] = localStorage.getItem('emailId');
  request.headers['country'] = sessionStorage.getItem('countryCode');
  return request
}

const handleErrorRequest = (error) => {
  document.body.classList.remove('loading_indicator')
  counter = counter + 1;
  return Promise.reject(error)
}

const handleSuccessWithoutTokenRefresh = (response) => {
  counter = counter -1 ;
  if(counter === 0 ){
    document.body.classList.remove('loading_indicator')
  }
  return response
}

const handleSuccess = (response) => {
  counter = counter -1 ;
  if(counter === 0 ){
    document.body.classList.remove('loading_indicator')
  }
  localStorage.setItem('idtoken',response.headers['idtoken']);
  localStorage.setItem('accesstoken',response.headers['accesstoken']);
  return response
}

const handleError = (error) => {

  counter = counter - 1;
  if(counter === 0 ){
    document.body.classList.remove('loading_indicator')
  }
  if (error.message === 'Network Error') {
    // The user doesn't have internet
    return Promise.reject(error.response)
  }
  try {
    switch (error.response.status) {
      case 400:
        document.body.classList.remove('loading_indicator')
        break
      case 401:
        //Un authorized
        document.body.classList.remove('loading_indicator')
        break
      case 403:
        //Un authorized
        document.body.classList.remove('loading_indicator')
        window.alert("Invalid Session");
        localStorage.clear();
        window.location.href='/';
        break
      case 404:
        // Show 404 page
        break
      case 500:
        // Serveur Error redirect to 500
        break
      default:
        Promise.reject(error.response)
        break
    }
  } catch (e) {
  }
  return Promise.reject(error.response)
}

stage = sessionStorage.getItem(constants.SPECTROMETER.STAGE);
//User analytics integration(Spectrometer)
const cathodeConfig = {
  siteName: constants.SPECTROMETER.SITE_NAME,
  appName: (stage !== null &&  stage !== undefined) ? constants.SPECTROMETER.APP_NAME+"-"+stage :
      constants.SPECTROMETER.APP_NAME
}

const scriptTags = cathodeVersions.getSpectrometerScriptTags(cathodeConfig, true)
document.write(scriptTags.boomerangLoaderScript)
document.write(scriptTags.listenerScripts)
document.write(scriptTags.cathodeScript)

init()

export {
  candidateAxios,
  candidatePortalURL,
  phoAxios,
  phoPortalURL,
  init
}