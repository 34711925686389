import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as InformationCircleFillIcon } from '../../assets/images/information-circle-fill.svg';

const iconMapping = {
  error: <></>, // TODO - Not needed as it will be throwaway work
  info: <InformationCircleFillIcon />, // TODO - Not needed as it will be throwaway work
  success: <></>, // TODO - Not needed as it will be throwaway work
  warning: <></>, // TODO - Not needed as it will be throwaway work
};

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    borderLeft: '8px solid #379AEC',
    color: theme.palette.neutral[90],
    fontSize: '1rem',
    lineHeight: '1.5',
    borderRadius: '0.5rem',
    padding: theme.spacing(1, 4),

    '&>.MuiAlert-icon': {
      display: 'flex',
      alignItems: 'center',
      width: '16px',
      height: '24px',
      marginRight: theme.spacing(2.5),
      color: theme.palette.primary.main,
    },
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

export function MessageBanner({ className, ...props }) {
  const classes = useStyles();
  return (
    <Alert
      className={[classes.root, className].filter(Boolean).join(' ')}
      iconMapping={iconMapping}
      severity="info" // TODO - Other severities not needed, as it will be throwaway work
      {...props}
    />
  );
}

export function MessageBannerLink({ className, children, icon, iconPosition = 'trailing', ...props }) {
  const classes = useStyles();
  return (
    <a className={[classes.link, className].filter(Boolean).join(' ')} {...props}>
      {iconPosition === 'leading' && icon}
      {children}
      {iconPosition === 'trailing' && icon}
    </a>
  );
}
