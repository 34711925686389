import React, { Component } from 'react'
import {NavLink} from "react-router-dom";

class TabMain extends Component {

    setSelected = (tab) => {
        this.setState({selected: tab});
    }

    render() {
        return (
            <div>
                <ul className='amz-tabs'>
                    <NavLink className="amz-tab-item" activeClassName="active" to="/candidate-dashboard">
                        <li className='amz-tab-link'>Candidate Dashboard</li>
                    </NavLink>
                </ul>
            </div>
        );
    }
}
export default TabMain
