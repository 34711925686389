import {candidateAxios} from "../api/axios";

export const ActivityServices = {
    getApplicationsByCandidate,
    candidateActionRequest,
    getCandidateProfileByEmail
}

async function getApplicationsByCandidate(body) {
    const response = await candidateAxios.post(`/candidates/getApplicationsByEmail`,body);
    if(response["data"]["sessionExpired"]){
        window.alert("Invalid Session");
        localStorage.clear();
        window.location.href='/';
    }else if(response["data"]["accessDenied"]){
        window.alert("Access Denied");
        window.location.href='/';
    }
    return response;
}

async function getCandidateProfileByEmail(body) {
    const response = await candidateAxios.post(`/candidates/getCandidateProfileByEmail`,body);
    if(response["data"]["sessionExpired"]){
        window.alert("Invalid Session");
        localStorage.clear();
        window.location.href='/';
    }else if(response["data"]["accessDenied"]){
        window.alert("Access Denied");
        window.location.href='/';
    }
    return response;
}

async function candidateActionRequest(body) {
    const response = await candidateAxios.post(`/candidates/editHiringStage`,body);
    if(response["data"]["sessionExpired"]){
        window.alert("Invalid Session");
        localStorage.clear();
        window.location.href='/';
    }else if(response["data"]["accessDenied"]){
        window.alert("Access Denied");
        window.location.href='/';
    }
    return response;
}