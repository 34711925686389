import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: '#23756B',
    fontSize: '0.875rem',
  },
}));

function UserAvatar() {
  const classes = useStyles();
  const [sessionUser, setSessionUser] = useState('');

  useEffect(() => {
    const localSessionUser = localStorage.getItem('session_user');

    if (localSessionUser) {
      setSessionUser(localSessionUser);
    }
  }, []);

  return (
    <div className={classes.root}>
      <Avatar className={classes.small}> {sessionUser ? sessionUser.charAt(0) : ''} </Avatar>
    </div>
  );
}

export default UserAvatar;
