/* istanbul ignore file */
import {phoAxios} from '../api/axios'

export const PhoService = {
    getAssignedPhoByCandidateId
}

function getAssignedPhoByCandidateId(body){
    return phoAxios.get(`/pho/assignedByCandidateId`, {params:{candidateId:body.candidateId}});
}

