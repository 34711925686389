import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderRadius: '40px',
    textTransform: 'none',
    padding: theme.spacing(2, 6),
    fontSize: '1rem',
    lineHeight: '1.5rem',
    '&:hover': {
      backgroundColor: '#FEF0EF',
    },
  },
}));

function ButtonError(props) {
  const classes = useStyles();
  return <Button className={classes.root} variant="outlined" {...props} />;
}

export default ButtonError;
