import constants from "./Constants";

export function sortApplications(applications, sortColumn){
    return applications.sort(compare(sortColumn))
}

function compare(property){
    return function (sortObject1, sortObject2){
        const sortValue1 = getJobStatusOrder(sortObject1[constants.DASHBOARD.JOB_STATUS])
        const sortValue2 = getJobStatusOrder(sortObject2[constants.DASHBOARD.JOB_STATUS])
        const hiringStage1 = constants.HIRING_STAGES.ORDER[sortObject1[property]]?constants.HIRING_STAGES.ORDER[sortObject1[property]]:0
        const hiringStage2 = constants.HIRING_STAGES.ORDER[sortObject2[property]]?constants.HIRING_STAGES.ORDER[sortObject2[property]]:0
        if(sortValue1 > sortValue2)
            return -1
        else if(sortValue1 === sortValue2)
            return hiringStage1<hiringStage2
        return 1
    }
}

function getJobStatusOrder(jobStatus){
    if(constants.JOB_STATUS.GREY_STATUSES.includes(jobStatus)){
        return 0;
    }
    else if(constants.JOB_STATUS.DISCONTINUE_STATUSES.includes(jobStatus)){
        return 1;
    }
    else return 2;
}