import React from "react";
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { useParams } from "react-router";
import constants from "../../Utils/Constants";
import { withAlertSnackBar } from "../../HOComponents/AlertSnackBarHOC";
import { useEffect, useState } from "react";
import { JobServices } from "../../Services/JobService";
import job_pic from "../../assets/images/job_pic.png"
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email'
import BusinessIcon from '@material-ui/icons/Business'
import Footer from "../../components/Footer/Footer";


function JobDetails(props) {

    let params = useParams();

    const [jobDetailsForm, setJobDetailsForm] = useState({ isValid : true, constantJobDetails : constants.EMPTY_JOB_DETAILS });

    useEffect(()=>{
      async function fetchData() {
        await JobServices.getJobDetailsByJobId(params.jobId).then((data) => {
            const jobDetails = {...data.data,'jobId' : params.jobId, isValid : true, constantJobDetails : constants.JOB_DETAILS };
            setJobDetailsForm(jobDetails);
        })
        .catch((error) => {
            const jobDetails = {'jobId' : "", isValid : false, constantJobDetails : constants.EMPTY_JOB_DETAILS };
            setJobDetailsForm(jobDetails);
            props.snackbarShowMessage(`${error.data.message}`,"error");
        })
      }  

      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])


    const getJobDetailsHeader = () =>{

      return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="jobDetailsHeaderCustomPadding">
        <Grid container alignItems="center" className="form_group_item">
            
            <Grid item xl={1} lg={1} md={2} sm={3} xs={3} className="text-left d-flex">
                <img src={job_pic} alt={"Box"}></img>
            </Grid>
            
            <Grid container item xl={8} lg={8} md={6} sm={12} xs={12} style={{paddingLeft:"5%"}} className="form_group_item">
                
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                    <h1 className="bolder-text" >{jobDetailsForm.jobRole}</h1>
                </Grid>
                
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                    <h3 style={{paddingTop:"1%"}}> <span className="bold-text">Job Id : </span>{jobDetailsForm.jobId}</h3> 
                </Grid>

            </Grid>


            <Grid container item xl={3} lg={3} md={4} sm={12} xs={12} style={{paddingLeft:"5%", paddingTop:"5%"}} className="form_group_item">
                
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex">
                    <h3 style={{paddingBottom:"2%"}}> <span className="bold-text">Apply Here : </span></h3> 
                </Grid>
                
                <br/>
                
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex" style={{paddingLeft:"10%"}}>
                    <h4> <BusinessIcon/>  &nbsp; {jobDetailsForm.agency}</h4> 
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex" style={{paddingLeft:"10%"}}>
                    <h4> <EmailIcon/>  &nbsp; <a href={`mailto:${jobDetailsForm.vendorKamEmail}`}>{jobDetailsForm.vendorKamEmail}</a></h4> 
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="text-left d-flex" style={{paddingLeft:"10%"}}>
                    <h4> <CallIcon/>  &nbsp; <a href={`tel:${jobDetailsForm.vendorKamPhone}`}>{jobDetailsForm.vendorKamPhone}</a></h4> 
                </Grid>

            </Grid>

        </Grid>

    </Grid>
      )
    }


    const getJobOverview = () =>{

      return (
                <Grid  item xl={12} lg={12} md={12} sm={12} xs={12} className="jobDetailsCustomPadding">
                    
                  <h2 className="bolder-text">{jobDetailsForm.constantJobDetails.Overview.Header}</h2>
                  <br/>
                  <h4>{jobDetailsForm.constantJobDetails.Overview.Description}</h4>
                  <ul>
                    <li style={{padding:"0.5%"}} ><span className="bold-text">{"Type of Contract : "}</span>{jobDetailsForm.contractType}</li>
                    { (jobDetailsForm.contractType==="FIXED") && <li style={{padding:"0.5%"}} ><span className="bold-text">{"Contract Tenure : "}</span>{jobDetailsForm.tenure} days</li>}
                    <li style={{padding:"0.5%"}} ><span className="bold-text">{"Location : "}</span>{jobDetailsForm.city}</li>
                    <li style={{padding:"0.5%"}} ><span className="bold-text">{"Job Type : "}</span>{jobDetailsForm.jobType}</li>
                    <li style={{padding:"0.5%"}} ><span className="bold-text">{"Start Date : "}</span>{jobDetailsForm.startDate}</li>
                  </ul>

                </Grid>
      )
    }

    const getDutiesAndResponsibility = () =>{
      
      const duties = jobDetailsForm.constantJobDetails.DutiesAndResponsibilities.Duties;
      const listItems = duties.map((duty) =>
        <li style={{padding:"0.5%"}} ><span>{duty}</span></li>
      );

      return (
                <Grid  item xl={12} lg={12} md={12} sm={12} xs={12} className="jobDetailsCustomPadding">
                    
                  <h2 className="bolder-text">{jobDetailsForm.constantJobDetails.DutiesAndResponsibilities.Header}</h2>
                  <br/>
                  <h4>{jobDetailsForm.constantJobDetails.DutiesAndResponsibilities.Description}</h4>
                  <ul>
                    { listItems}
                  </ul>

                </Grid>
      )
    }

    const getWhatItsLikeAtTheSite = () =>{
      
      const likes = jobDetailsForm.constantJobDetails.WhatItsLikeAtTheSite.Likes;
      const listItems = likes.map((like) =>
        <li style={{padding:"0.5%"}} ><span className="bold-text">{like.KEY}</span>{like.VALUE}</li>
      );
      
      return (
                <Grid  item xl={12} lg={12} md={12} sm={12} xs={12} className="jobDetailsCustomPadding">
                    
                    <h2 className="bolder-text">{jobDetailsForm.constantJobDetails.WhatItsLikeAtTheSite.Header}</h2>
                    <ul>
                      { listItems}
                    </ul>

                </Grid>
      )
    }

    const getWhyYouWillLoveThisJob  = () =>{
      const likes = jobDetailsForm.constantJobDetails.WhyYouWillLoveThisJob.Likes;
      const listItems = likes.map((like) =>
        <li style={{padding:"0.5%"}} ><span className="bold-text">{like.KEY}</span>{like.VALUE}</li>
      );
      
      return (
                <Grid  item xl={12} lg={12} md={12} sm={12} xs={12} className="jobDetailsCustomPadding">
                    
                    <h2 className="bolder-text">{jobDetailsForm.constantJobDetails.WhyYouWillLoveThisJob.Header}</h2>
                    <ul>
                      { listItems}
                    </ul>

                </Grid>
      )
    }

    const getMinimumQualifications = () =>{

      const qualifications = jobDetailsForm.constantJobDetails.MinimumQualifications.Qualifications;
      const listItems = qualifications.map((qualification) =>
        <li style={{padding:"0.5%"}} ><span>{qualification}</span></li>
      );

      return (
                <Grid  item xl={12} lg={12} md={12} sm={12} xs={12} className="jobDetailsCustomPadding">
                    
                  <h2 className="bolder-text">{jobDetailsForm.constantJobDetails.MinimumQualifications.Header}</h2>
                  <ul>
                    { listItems}
                  </ul>

                </Grid>
      )

    }

    const getContactDetails = () =>{

      return (
                <div className="jobDetailsCustomPadding">

                  <Grid  item xl={12} lg={12} md={12} sm={12} xs={12}>
                      
                    <h2 className="bolder-text">{"Contact Details:"}</h2>
                    <br/>
                    <h4>{"Contact the below if you interested in the Job"}</h4>
                    <ul>
                    <li style={{padding:"0.5%"}} ><span className="bold-text">{"Agency Name : "}</span>{jobDetailsForm.agency}</li>
                    <li style={{padding:"0.5%"}} ><span className="bold-text">{"Email : "}</span>{jobDetailsForm.vendorKamEmail}</li>
                    <li style={{padding:"0.5%"}} ><span className="bold-text">{"Phone : "}</span>{jobDetailsForm.vendorKamPhone}</li>
                    </ul>

                  </Grid>

                </div>
      )
    }


    return (
            <div style={{background:"#f1f4f7"}} className=' view_page_module view_page' >

                {!jobDetailsForm.isValid && <div style={{background:"#d32f2f", padding:"1%", color:"WHITE"}}>
                    Sorry about that, Requested resource doesn't exist!
                </div>}

                {<Container maxWidth="xl" style={{paddingLeft:"5%", paddingRight:"5%"}}>

                    {getJobDetailsHeader()}

                    {getJobOverview()}

                    <br/>
                    
                    {getDutiesAndResponsibility()}

                    <br/>

                    {getWhatItsLikeAtTheSite()}

                    <br/>

                    {getWhyYouWillLoveThisJob()}

                    <br/>

                    {getMinimumQualifications()}

                    <br/>

                    {getContactDetails()}

                    <br/>
        
                </Container>}

                <Footer/>
              
            </div>

            
          );
        }

export default withAlertSnackBar(JobDetails);

