import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {Breadcrumbs, Container, Grid, Typography} from "@mui/material";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {useHistory, useLocation} from "react-router-dom";
import DocumentCard from "./DocumentCard";
import constants from "../../Utils/Constants";
import Link from "@material-ui/core/Link";
import {useEffect, useRef, useState} from "react";
import {useGetDocuments} from "../../ReactQueries/hooks/useGetDocuments";

function Documents(props){
    const history = useHistory()
    const location = useLocation();
    const {application} = location.state
    const [docMap, setDocMap] = useState({})
    const { data: candidateDocuments} = useGetDocuments(application.applicationId)
    let documents  = useRef([{type: constants.DOCUMENTS.TYPE.LETTER_OF_INTENT}]);
    // TODO : Use DMS get document API to list the document instead of hard coding the list
    if((application.hiringStage === constants.DOCUMENTS.STATUS.OFFER_LETTER_ACCEPTED || application.hiringStage === constants.DOCUMENTS.STATUS.OFFER_LETTER_SENT) && documents.current.length <2){
        documents.current = documents.current.concat({type: constants.DOCUMENTS.TYPE.OFFER_LETTER})
    }

    useEffect(() => {
        let docMapDict = {}
        if(candidateDocuments && candidateDocuments.data){
            candidateDocuments.data.documents.forEach(doc => {
                docMapDict[constants.DOCUMENTS.DMS_DOCUMENT_TYPE_MAP[doc.documentType]]= doc
                if(doc.documentType === "OFFER_LETTER" && documents.current.length <2)
                    documents.current = documents.current.concat({type: constants.DOCUMENTS.TYPE.OFFER_LETTER})
            })
        }
        setDocMap(docMapDict)
    } , [candidateDocuments])

    return (<>

        <Container sx={{ maxWidth:'90%' }} maxWidth={false}>
            <Grid container>
                <Grid sx={{marginTop:"10px"}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div >
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            <Link className="primary_color" onClick={() => (history.push(`${constants.DASHBOARD.ROUTE}`))}>
                                {constants.DASHBOARD.TITLE}
                            </Link>
                            <Typography color="textPrimary">{constants.DOCUMENTS.TITLE}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
                <Typography sx={{ fontSize: 18, margin:'10px'  }} >{application.jobId}</Typography>
                {documents.current.map((document)=> (
                    <DocumentCard document={document} application={application} docData = {docMap[document.type]}></DocumentCard>
                ))}
            </Grid>
        </Container>
    </>);
}

export default withAlertSnackBar(Documents);