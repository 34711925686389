import {candidateAxios} from "../api/axios";
import constants from "../Utils/Constants"

export const JobServices = {
    getJobDetailsByJobId,
}

async function getJobDetailsByJobId(jobId) {
    const url = (localStorage.getItem('idtoken') === 'undefined' || localStorage.getItem('idtoken') === 'null') ? constants.JOB_DETAILS_API_ENDPOINT.PUBLIC : constants.JOB_DETAILS_API_ENDPOINT.PRIVATE;
    return await candidateAxios.get(`${url}${jobId}`)
}