import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {Grid} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {CircularProgress} from "@mui/material";

function DocumentFrame(props){

    const [iframeTimeoutId, setIframeTimeoutId] = useState(undefined);
    const iframeRef = useRef(null);
    const [iframeLoaded, setIframeLoaded] = useState(false);

    useEffect(()=>{
        const intervalId = setInterval(
            updateIframeSrc, 1000 * 10
        );
        setIframeTimeoutId(intervalId)
        // eslint-disable-next-line
    },[]);

    function handleIframeLoad() {
        setIframeLoaded(true)
        clearInterval(iframeTimeoutId);
    }
    function getIframeLink() {
        return `https://docs.google.com/gview?url=${encodeURIComponent(props.filePath)}&embedded=true`;
    }
    function updateIframeSrc() {
        if(iframeRef.current){
            let filepath = getIframeLink()
            iframeRef.current.src = filepath;
        }
    }

    return(
        <Grid container direction={"row"} height={"40rem"} alignItems={"center"} justifyContent={"center"} >
            <Grid item>{!iframeLoaded && <CircularProgress size={"5rem"} />}</Grid>
            <Grid item container justifyContent={"center"} xs={12} sm={12} md={12} lg={12} xl={12} height={"100%"}>{props.filePath &&
                <iframe
                    id="msdoc-iframe"
                    title="msdoc-iframe"
                    height="100%"
                    width="85%"
                    onLoad={handleIframeLoad}
                    onError={updateIframeSrc}
                    ref={iframeRef}
                    src={getIframeLink()}
                    frameBorder="0"
                />}</Grid>

        </Grid>
    )
}
export default withAlertSnackBar(DocumentFrame);