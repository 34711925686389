import React, {useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ButtonPrimary from '../../components/ButtonPrimary/ButtonPrimary';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, InputAdornment, Input} from "@material-ui/core";
import InputLabel from "../../components/FormPage/InputLabel";
import {FormPasswordMismatchError, Formvalidationerror} from "../../components/FormPage/FormValidationError";
import Formfieldstar from "../../components/FormPage/FormFieldStar";
import {validatePattern} from "../../Utils/Validator";
import constants from "../../Utils/Constants";
import InfoHelpIcon from "../../components/FormPage/InfoHelpIcon";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {UserServices} from "../../Services/users";
import {handleCognitoException} from "../../Utils/Exceptions";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(4),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    }
}));

function ResetPasswordDialog(props) {
    const theme = useTheme();
    useMediaQuery(theme.breakpoints.down('md'));
    const [formData, setFormData] = React.useState({});
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const [isOtpSent, setIsOtpSent] = React.useState(false);
    const classes = useStyles();
    const [otpTitle, setOtpTitle] = React.useState('Send OTP')
    const [isOtpDisabled, setIsOtpDisabled] = React.useState(false);
    const [counter, setCounter] = React.useState(30);
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            }
            if (counter === 0) {
               clearInterval(myInterval)
                setIsOtpDisabled(false);
                setCounter(30);
            }
        }, 1000)
        return () => {clearInterval(myInterval)};
    });

    const [newPasswordValues, setNewPasswordValues] = React.useState({
        password: '',
        showPassword: false,
    });
    const [confirmPasswordValues, setConfirmNewPasswordValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const resetFormValues = () => {
        props.formData.userName = '';
        props.formData.password = '';
        newPasswordValues.password = '';
        newPasswordValues.showPassword = false;
        confirmPasswordValues.password = '';
        confirmPasswordValues.showPassword = false;
        formData.userName = '';
        formData.Otp = '';
        setIsFormSubmitted(false);
        props.setIsFormSubmitted(false);
        props.setIsForgotPasswordRequired(false);
    }


    const cognitoSendOtp = async () => {
        let body = {
            userName: formData.userName
        };
        await UserServices.sendOtp(body)
            .then((data)=>{
                if(data.data.error) {
                    props.snackbarShowMessage(`${handleCognitoException(data.data.error.code)}`, "error", "10000");
                }
                else if(data.data.message) {
                    resetFormValues();
                    props.snackbarShowMessage(`${data.data.message}`, "error", "10000")
                }
                else {
                    props.snackbarShowMessage(`${constants.AUTH.OTP_SENT_SUCCESS}`, "success", "10000")
                }
            })
            .catch((err)=>{
                props.snackbarShowMessage(`${constants.AUTH.OTP_SENT_FAILED}`,"error", "10000");
            })
    }

    const cognitoForgotPassword = async () => {
        let body = {
            userName: formData.userName,
            otp: formData.Otp,
            password: newPasswordValues.password,
        }
        await UserServices.forgotPassword(body)
            .then((data)=>{
                const userData = data.data;
                if(!userData.login) {
                    props.snackbarShowMessage(handleCognitoException(userData.error.code), "error", "10000");
                }
                else {
                    resetFormValues();
                    setLoginAndRedirect(userData)
                    props.snackbarShowMessage(`${constants.AUTH.SIGNIN_SUCCESS}`);

                }
            })
            .catch((err)=>{
                props.snackbarShowMessage(`${constants.AUTH.PASSWORD_CHANGE_FAILED}`,"error", "10000");
            })
    }
    const setLoginAndRedirect = async (userData) => {
        try {
            localStorage.setItem("session_user", userData.userName.split('@')[0]);
            localStorage.setItem("accesstoken", userData.accesstoken);
            localStorage.setItem("idtoken", userData.idtoken);
            localStorage.setItem("refreshtoken", userData.refreshtoken);
            localStorage.setItem("candidateId", userData.candidateId)
            localStorage.setItem("emailId", userData.userName)

            props.snackbarShowMessage(`${constants.AUTH.SIGNIN_SUCCESS}`);
            if(localStorage.getItem("location")){
                window.setTimeout(() => {
                    window.location.href = localStorage.getItem("location");
                }, 1000);                }
            else{
                window.setTimeout(() => {
                    window.location.href = `${constants.DASHBOARD.ROUTE}`;
                }, 1000);
            }
        } catch(error)
        {
            props.snackbarShowMessage(`${constants.AUTH.SIGNIN_FAILED}`);
        }
    };

    const validateForm = () => {
        return newPasswordValues.password && confirmPasswordValues.password && validatePattern(new RegExp(constants.PATTERN.PASSWORD_PATTERN), newPasswordValues.password) && newPasswordValues.password === confirmPasswordValues.password
            && formData.userName && formData.Otp;
    }

    const handleConfirm = async () => {
        setIsFormSubmitted(true);
        if(validateForm()) {
            await cognitoForgotPassword()
        }
    }

    const handleCancel = () => {
        setIsFormSubmitted(false);
        newPasswordValues.password = '';
        newPasswordValues.showPassword = false;
        confirmPasswordValues.password = '';
        confirmPasswordValues.showPassword = false;
        props.setIsForgotPasswordRequired(false);
    }

    const handlePasswordChange = (prop) => (event) => {
        setNewPasswordValues({ ...newPasswordValues, [prop]: event.target.value });
    };

    const handleConfirmPasswordChange = (prop) => (event) => {
        setConfirmNewPasswordValues({ ...confirmPasswordValues, [prop]: event.target.value });
    };

    const handleText = (event) => {
        let formDataL = { ...formData };
        formDataL[event.target.id] = event.target.value;
        setFormData(formDataL);
    };

    const handleSendOtp = async () => {
        setIsOtpSent(true);
        if(formData.userName) {
            setIsOtpDisabled(true);
            setOtpTitle('Resend OTP')
            setCounter(30);
            await cognitoSendOtp();
        }
    }

    const handleClickShowPassword = () => {
        document.getElementById('newPassword').focus();
        setNewPasswordValues({
            ...newPasswordValues,
            showPassword: !newPasswordValues.showPassword,
        });
    };

    const handleClickShowConfirmPassword = () => {
        document.getElementById('confirmPassword').focus();
        setConfirmNewPasswordValues({
            ...confirmPasswordValues,
            showPassword: !confirmPasswordValues.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const checkValidationPattern = (elementId,regex,data,error) => {
        if(data) {
            if(!validatePattern(regex, data) ){
                document.getElementById(elementId).innerHTML = error
            }else{
                document.getElementById(elementId).innerHTML = ''
            }
        }
    }

    const handleValidationError = (elementId) => {
        switch (elementId) {
            case 'NewPasswordError' :
                checkValidationPattern(elementId, new RegExp(constants.PATTERN.PASSWORD_PATTERN), newPasswordValues.password, 'Invalid Password Format');
                break;
            default :
        }
    }

    const removeValidationError = (elementId) => {
        document.getElementById(elementId).innerHTML = ''
    }

    const passwordMatchCheck = (elementId) => {
        if(confirmPasswordValues.password && newPasswordValues.password !== confirmPasswordValues.password) {
            document.getElementById(elementId).innerHTML = 'Passwords do not match';
        }
    }

    return (
        <div>
            <Dialog
                open={true}>
                <MuiDialogTitle disableTypography className={classes.root}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleCancel}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h5" style = {{marginLeft: "2rem"}}>Change your password!</Typography>
                        <p style = {{fontSize: "1rem", marginTop: "1rem", marginLeft: "2rem"}}>In order to protect your account, make sure your password:</p>
                        <ul style = {{fontSize: "1rem", marginTop: "1rem", marginLeft: "1rem"}}>
                            <li>Has atleast 8 characters</li>
                            <li>A mixture of both uppercase and lowercase letters</li>
                            <li>A mixture of letters and numbers</li>
                            <li>Inclusion of at least one special character</li>
                        </ul>
                        <Grid container alignItems="top" className="form_group_list">
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                        <InputLabel Title="Username:" />
                                        <Formfieldstar/>
                                        <InfoHelpIcon
                                            FieldToolTip="This is the email with which you have registered your account"
                                            IconClassName={<InfoOutlinedIcon />}
                                            Title="Username"
                                        />
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                                        <Input
                                            id="userName"
                                            type = 'text'
                                            value={formData.userName}
                                            onChange={handleText}
                                        />
                                        {(isFormSubmitted || isOtpSent) && !formData.userName && (
                                            <Formvalidationerror fieldName = "Username"/>
                                        )}
                                </Grid>
                            </Grid>
                            </Grid>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container direction="row" justify="flex-end" alignItems="flex-start" style = {{marginRight: "3.4rem"}}>
                                    <ButtonPrimary id = "sendOtp" variant="outlined" Title={otpTitle}  isDisabled = {isOtpDisabled}  onClick={handleSendOtp} color="primary"/>
                                </Grid>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} direction="row" justify="flex-end" alignItems="flex-start">
                                    {isOtpDisabled && <Typography fontWeight={500} style = {{marginLeft: '1rem'}} align="center" color='textSecondary'> Resend OTP in <span style={{color:"green",fontWeight:"bold"}}> {counter}</span> seconds </Typography>}
                                </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style = {{marginTop: "1rem"}}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                        <InputLabel Title="New Password:" />
                                        <Formfieldstar/>
                                        <InfoHelpIcon
                                            FieldToolTip="Password should be atleast 8 characters long and should contain atleast one small letter,capital letter,number and a special character"
                                            IconClassName={<InfoOutlinedIcon />}
                                            Title="New Password"
                                        />
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                                        <Input
                                            id="newPassword"
                                            style = {{width: '15.34rem'}}
                                            type={newPasswordValues.showPassword ? 'text' : 'password'}
                                            value={newPasswordValues.password}
                                            onChange={handlePasswordChange('password')}
                                            onClick = {()=> removeValidationError('NewPasswordError')}
                                            onBlur = {() => handleValidationError('NewPasswordError')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {newPasswordValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                        <small><p id='NewPasswordError' className='amz_field_error'></p></small>
                                        {isFormSubmitted && !newPasswordValues.password && (
                                            <Formvalidationerror fieldName = "New password"/>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style = {{marginTop: "1rem"}}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                        <InputLabel Title="Confirm New Password:" />
                                        <Formfieldstar/>
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                                        <Input
                                            id="confirmPassword"
                                            style = {{width: '15.34rem'}}
                                            type={confirmPasswordValues.showPassword ? 'text' : 'password'}
                                            value={confirmPasswordValues.password}
                                            onChange={handleConfirmPasswordChange('password')}
                                            onClick = {()=> removeValidationError('ConfirmPasswordError')}
                                            onBlur = {() => passwordMatchCheck('ConfirmPasswordError')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {confirmPasswordValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                        <small><p id='ConfirmPasswordError' className='amz_field_error'></p></small>
                                        {isFormSubmitted && !confirmPasswordValues.password && (
                                            <Formvalidationerror fieldName = "Confirm New Password"/>
                                        )}
                                        {isFormSubmitted && !confirmPasswordValues.password && !newPasswordValues.password && newPasswordValues.password!==confirmPasswordValues.password &&
                                        (
                                            <FormPasswordMismatchError/>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style = {{marginTop: "1rem"}}>
                                <Grid container alignItems="center" className="form_group_item">
                                    <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                        <InputLabel Title="OTP:" />
                                        <Formfieldstar/>
                                        <InfoHelpIcon
                                            FieldToolTip="OTP will be sent to your registered email"
                                            IconClassName={<InfoOutlinedIcon />}
                                            Title="OTP"
                                        />
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                                        <Input
                                            id="Otp"
                                            type = 'text'
                                            value={formData.Otp}
                                            onChange={handleText}
                                        />
                                        {isFormSubmitted && !formData.Otp && (
                                            <Formvalidationerror fieldName = "OTP"/>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonPrimary variant="outlined" style = {{marginRight: "2rem"}}Title="Save changes and Sign-In"  onClick={handleConfirm} color="primary" />
                </DialogActions>
            </Dialog>
        </div>
    )
}

ResetPasswordDialog.propTypes = {

}

export default ResetPasswordDialog