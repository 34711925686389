import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {Breadcrumbs, Checkbox, Container, FormControlLabel, Grid, Typography} from "@mui/material";
import {useHistory, useLocation} from "react-router-dom";
import {UploadDownloadS3File} from "../../Services/upload_download_s3file";
import {useEffect, useState} from "react";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import constants from "../../Utils/Constants";
import {ActivityServices} from "../../Services/activities";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Link from "@material-ui/core/Link";
import DocumentFrame from "./DocumentFrame";
import {DocumentServices} from "../../Services/documents";
import {getDMSFeatureFlag} from "../../Utils/DMSConstants";
import {useQueryClient} from "react-query";
import Constants from "../../Utils/Constants";

function ViewDocument(props){
    const location = useLocation()
    const history = useHistory()
    const { documentType, application, documentData } = location.state
    const [ checkBoxState, setCheckBoxState] = useState(false);
    const [filepath, setFilePath] = useState("");

    let viewAcceptDocumentState = [Constants.DOCUMENT_STATUS.EXTENDED, Constants.DOCUMENT_STATUS.REGENERATED_EXTENDED]

    const queryClient = useQueryClient()

    const documentActivity = async () => {
        if(documentData && getDMSFeatureFlag()){
            setFilePath(documentData.documentURL)
            return;
        }
        await UploadDownloadS3File.downloadConstruct(getDocumentLink()).then((data) => {
            setFilePath(data.data.fileSignedLink)
        })
    }

    const getDocumentLink = () => {
        if(documentType === constants.DOCUMENTS.TYPE.LETTER_OF_INTENT && application.refId && application.vendorId && application.city){
            return `${constants.DOCUMENTS.DOCUMENT_PREFIX_MAP[documentType]}/${application.refId}_${application.vendorId}_${application.city}_${constants.DOCUMENTS.DOCUMENT_PREFIX_MAP[documentType]}.${constants.DOCUMENTS.DOCUMENT_FORMAT[documentType]}`;
        }
        else{
            return `${constants.DOCUMENTS.DOCUMENT_PREFIX_MAP[documentType]}/${application.applicationId}_${constants.DOCUMENTS.DOCUMENT_PREFIX_MAP[documentType]}.${constants.DOCUMENTS.DOCUMENT_FORMAT[documentType]}`;
        }
    }

    const handleCheckBoxChange = (e) => {
        setCheckBoxState(!checkBoxState)
    }

    const handleAcceptActivity = () => {
        if(documentData && getDMSFeatureFlag()){
            DocumentServices.signDocument({
                documentId : [documentData.documentId],
                modifiedBy: localStorage.getItem('emailId')
            })
                .then(async response => {
                    console.log(response)
                    props.snackbarShowMessage(
                        `${constants.CRUD_SUCCESS_ALERTS.UPDATED}`
                    );
                    await queryClient.invalidateQueries([Constants.REACT_QUERY.QUERY_KEY.GET_DOCUMENTS, application.applicationId])
                    window.setTimeout(() => {
                        history.push(`${constants.DASHBOARD.ROUTE}`)
                    }, 1000)
                })
                .catch(error => {
                    console.log(error)
                    props.snackbarShowMessage(
                        `${constants.ERROR.UPDATE_ERROR} ${error.data.message}`,
                        "error",
                        "5000",
                        error.data.message
                    );
                })
            return;
        }

        let body = {
            candidatesInput:[{
                candidateId:application.applicationId,
                hiringStage: documentType === constants.DOCUMENTS.TYPE.LETTER_OF_INTENT? constants.DOCUMENTS.STATUS.LOI_ACCEPTED: constants.DOCUMENTS.STATUS.OFFER_LETTER_ACCEPTED,
                operationType: documentType === constants.DOCUMENTS.TYPE.LETTER_OF_INTENT? constants.DOCUMENTS.OPERATION_TYPE.LOI_ACCEPTED: constants.DOCUMENTS.OPERATION_TYPE.OFFER_LETTER_ACCEPTED,
                email:localStorage.getItem('emailId')

            }],
            modifiedBy:localStorage.getItem('emailId'),
            actionType: constants.DOCUMENTS.ACTION_TYPE.UPDATE_CANDIDATE
        }
        ActivityServices.candidateActionRequest(body)
            .then((res) => {
                const status = res && res.data && res.data.candidatesOutput[0] && res.data.candidatesOutput[0].status
                if(status === "Success"){
                    props.snackbarShowMessage(
                        `${constants.CRUD_SUCCESS_ALERTS.UPDATED}`
                    );
                    window.setTimeout(() => {
                        history.push(`${constants.DASHBOARD.ROUTE}`)
                    }, 1000)

                }
                else{
                    props.snackbarShowMessage(
                        `${constants.ERROR.UPDATE_ERROR} ${res.data.message}`,
                        "error",
                        "5000",
                        res.data.message
                    );
                }

            })
            .catch((res, error) => {
                props.snackbarShowMessage(
                    `${constants.ERROR.UPDATE_ERROR} ${res.data.message}`,
                    "error",
                    "5000",
                    res.data.message
                );
            });
    }
    const displayAccept = (application) => {
        return (((documentType === constants.DOCUMENTS.TYPE.LETTER_OF_INTENT && application.hiringStage === constants.DOCUMENTS.STATUS.LOI_GENERATED) ||
                (documentType === constants.DOCUMENTS.TYPE.OFFER_LETTER && application.hiringStage === constants.DOCUMENTS.STATUS.OFFER_LETTER_SENT))
            && !constants.JOB_STATUS.DISCONTINUE_STATUSES.includes(application.jobStatus)) || (documentData? viewAcceptDocumentState.includes(documentData.documentStatus) : false)?"default":"none"
    }

    const handleDownloadActivity = async() => {
        if(documentData && getDMSFeatureFlag()){
            const outsideRes = await fetch(documentData.documentURL);
            const blob = await outsideRes.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${application.jobId}_${constants.DOCUMENTS.DOCUMENT_PREFIX_MAP[documentType]}.${constants.DOCUMENTS.DOCUMENT_FORMAT[documentType]}`;
            link.click();
            return;
        }
        const filenames = [`${constants.DOCUMENTS.DOCUMENT_PREFIX_MAP[documentType]}/${application.applicationId}_${constants.DOCUMENTS.DOCUMENT_PREFIX_MAP[documentType]}.${constants.DOCUMENTS.DOCUMENT_FORMAT[documentType]}`]
        await UploadDownloadS3File.downloadFiles(filenames,constants.S3.DOCUMENTS_BUCKET).then(data => {
            data.data.forEach((file) => {
                const bytes = new Uint8Array(file.Body.data);
                const blob = new Blob([bytes], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = `${application.jobId}_${constants.DOCUMENTS.DOCUMENT_PREFIX_MAP[documentType]}.${constants.DOCUMENTS.DOCUMENT_FORMAT[documentType]}`;
                link.click();
            })

        }).catch((error) => {
            props.snackbarShowMessage(`Some error occurred while downloading offer letter`, "error");
        })
    }

    useEffect(() => {
        documentActivity()
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <Container container>
                <Grid sx={{marginTop:"10px"}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div >
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            <Link className="primary_color" onClick={() => (history.push(`${constants.DASHBOARD.ROUTE}`))}>
                                {constants.DASHBOARD.TITLE}
                            </Link>
                            <Link className="primary_color" onClick={() => (history.push({pathname:`${constants.DOCUMENTS.ROUTE}/${application.jobId}`,
                                state:{application : application}}))}>
                                {constants.DOCUMENTS.TITLE}
                            </Link>
                            <Typography color="textPrimary">{documentType}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
                <Typography sx={{ fontSize: 18, margin:'10px' ,alignSelf:"center" }} >{application.jobId}</Typography>
                <DocumentFrame filePath = {filepath}></DocumentFrame>
                <Grid container direction="row"  justifyContent={"center"}  display={documentType === constants.DOCUMENTS.TYPE.OFFER_LETTER? "default": "none"}>
                    <ButtonPrimary onClick={() =>handleDownloadActivity()} id={`btnDownload`} size="large" sx={{margin: '0px'}} Title="Download"/>
                </Grid>
                <Grid display={displayAccept(application)} container direction="row"  justifyContent={"center"}>
                    <FormControlLabel control={<Checkbox
                        checked={checkBoxState}
                        onChange={handleCheckBoxChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} label={documentType === constants.DOCUMENTS.TYPE.LETTER_OF_INTENT ? constants.TEXTS.LOI_ACCEPT_TEXT: constants.TEXTS.OFFER_LETTER_ACCEPT_TEXT}>

                    </FormControlLabel>

                    <Grid>
                        <ButtonPrimary onClick={() =>handleAcceptActivity()} isDisabled={!checkBoxState} id={`btnAccept`} size="large" sx={{margin: '0px'}} Title="Accept"/>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default withAlertSnackBar(ViewDocument);