export function handleCognitoException (error) {
    switch (error) {
        case 'NotAuthorizedException' :
            return 'Invalid username or password'
        case 'CodeMismatchException' :
            return 'Invalid OTP'
        case 'ExpiredCodeException' :
            return 'Username is invalid or OTP was never requested. Please enter a valid username and request an OTP'
        case 'LimitExceededException' :
            return 'Too many failed attempts. Please try again later'
        case 'UserNotFoundException' :
            return 'Invalid username. User does not exist'
        default : return 'There was some error. Please try again'
    }
}