import React from 'react';
import { Tooltip as MUITooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
    '&:before': {
      border: `1px solid ${theme.palette.neutral['20']}`,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.neutral['90'],
    border: `1px solid ${theme.palette.neutral['20']}`,
    fontSize: '1rem',
    lineHeight: 1.5,
    fontWeight: '400',
    boxShadow: '0px 1px 10px 0px #232F3E33',
    padding: theme.spacing(2, 4),
  },
}));

function Tooltip(props) {
  const classes = useStyles();
  return <MUITooltip classes={classes} {...props} />;
}

export default Tooltip;
