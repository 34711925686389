import React, { memo } from 'react'
import { Route, Redirect } from 'react-router'

const Private = ({ component: Component, acl, auth, ...rest }) => {

    if(!auth){
        localStorage.setItem("location", window.location.href)
    }
    return (
        <Route {...rest} render={props => (
            auth ? <Component {...props} auth = {auth}/> : <Redirect to={'/sign-in'} />

        )} />
    )
}

export default memo(Private)
